<template>
    <div>
        <h2 class="light-blue" style="margin-bottom: 15px;font-weight: bolder">{{ $t("scoring_settings") }}</h2>
        
      <div>
        <b-tabs content-class="pt-1" fill v-model="tabIndex">
        <b-tab @click="getScoringAttributes('1')">
          <template #title>
            <feather-icon icon="FileTextIcon" />
            <span>{{ $t("seller") }}</span>
          </template>
          <b-card>
             <div v-for="scoring_attribute in scoring_attributes" v-if="!showLoader">  
        <b-card>
        
            <b-form>
              <b-row>
                <b-col cols="3">
                  <label>{{ $t("scoring_title") }} </label>
                  <b-form-input
                      disabled
                      name="title"
                      v-model="scoring_attribute.title"
                  ></b-form-input>
                </b-col>
                <b-col cols="3">
                  <label>{{ $t("scoring_source") }} </label>
                  <b-form-input
                      name="source"
                      disabled
                      v-model="scoring_attribute.source"
                  ></b-form-input>
                </b-col>
                <b-col cols="3">
                  <label>{{ $t("scoring_rule") }} </label>
                  <b-form-input
                      name="rule"
                      v-model="scoring_attribute.rule"
                      disabled
                  ></b-form-input>
                </b-col>
                <b-col cols="3">
                  <label>{{ $t("scoring_deafult_points") }} </label>
                  <b-form-input
                      required
                      name="default_points"
                      v-model="scoring_attribute.default_points"
                  ></b-form-input>
                </b-col>
              </b-row>
              <div style="display: flex;align-items: center;margin-top: 10px;justify-content: center">
                <div class="position-arrow" style="margin-right: 20px" v-if="scoring_attribute.scoring_values && scoring_attribute.scoring_values.length > 0">
                  <img src="/icons/right-arrow-green.png" style="width: 45px;" alt="">
                </div>
                <div class="position-arrow">
                  <b-row v-for="scoring_value in scoring_attribute.scoring_values">
                    <b-col cols="3">
                      <label>{{ $t("scoring_dimension") }} </label>
                      <b-form-input
                          required
                          name="dimension"
                          v-model="scoring_value.dimension"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <label>{{ $t("scoring_lower_border") }} </label>
                      <b-form-input
                          required
                          name="lower_border"
                          v-model="scoring_value.lower_border"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <label>{{ $t("scoring_upper_border") }} </label>
                      <b-form-input
                          required
                          name="upper_border"
                          v-model="scoring_value.upper_border"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <label>{{ $t("scoring_points") }} </label>
                      <b-form-input
                          required
                          name="points"
                          v-model="scoring_value.points"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <b-row>
                <b-col cols="12" class="text-right">
                  <hr />
                  <b-button variant="primary" @click="onSubmit(scoring_attribute.id)">{{
                      $t("save")
                    }}</b-button>
                </b-col>
              </b-row>
            </b-form>
        </b-card>
      </div>
          </b-card>
        </b-tab>
        <b-tab @click="getScoringAttributes('2')">
          <template #title>
            <feather-icon icon="FileTextIcon" />
            <span>{{ $t("debtor") }}</span>
          </template>
          <b-card>
            <div v-for="scoring_attribute in scoring_attributes" v-if="!showLoader">
       <b-card>
           <b-form>
             <b-row>
               <b-col cols="3">
                 <label>{{ $t("scoring_title") }} </label>
                 <b-form-input
                     disabled
                     name="title"
                     v-model="scoring_attribute.title"
                 ></b-form-input>
               </b-col>
               <b-col cols="3">
                 <label>{{ $t("scoring_source") }} </label>
                 <b-form-input
                     name="source"
                     disabled
                     v-model="scoring_attribute.source"
                 ></b-form-input>
               </b-col>
               <b-col cols="3">
                 <label>{{ $t("scoring_rule") }} </label>
                 <b-form-input
                     name="rule"
                     v-model="scoring_attribute.rule"
                     disabled
                 ></b-form-input>
               </b-col>
               <b-col cols="3">
                 <label>{{ $t("scoring_deafult_points") }} </label>
                 <b-form-input
                     required
                     name="default_points"
                     v-model="scoring_attribute.default_points"
                 ></b-form-input>
               </b-col>
             </b-row>
             <div style="display: flex;align-items: center;margin-top: 10px;justify-content: center">
               <div class="position-arrow" style="margin-right: 20px" v-if="scoring_attribute.scoring_values && scoring_attribute.scoring_values.length > 0">
                 <img src="/icons/right-arrow-green.png" style="width: 45px;" alt="">
               </div>
               <div class="position-arrow">
                 <b-row v-for="scoring_value in scoring_attribute.scoring_values">
                   <b-col cols="3">
                     <label>{{ $t("scoring_dimension") }} </label>
                     <b-form-input
                         required
                         name="dimension"
                         v-model="scoring_value.dimension"
                     ></b-form-input>
                   </b-col>
                   <b-col cols="3">
                     <label>{{ $t("scoring_lower_border") }} </label>
                     <b-form-input
                         required
                         name="lower_border"
                         v-model="scoring_value.lower_border"
                     ></b-form-input>
                   </b-col>
                   <b-col cols="3">
                     <label>{{ $t("scoring_upper_border") }} </label>
                     <b-form-input
                         required
                         name="upper_border"
                         v-model="scoring_value.upper_border"
                     ></b-form-input>
                   </b-col>
                   <b-col cols="3">
                     <label>{{ $t("scoring_points") }} </label>
                     <b-form-input
                         required
                         name="points"
                         v-model="scoring_value.points"
                     ></b-form-input>
                   </b-col>
                 </b-row>
               </div>
             </div>
             <b-row>
               <b-col cols="12" class="text-right">
                 <hr />
                 <b-button variant="primary" @click="onSubmit(scoring_attribute.id)">{{
                     $t("save")
                   }}</b-button>
               </b-col>
             </b-row>
           </b-form>
       </b-card>
     </div>
         </b-card>
        </b-tab>
      </b-tabs></div>
    
      <div v-if="showLoader">
        <img src="/loader.svg" />
        <!-- {{ JSON.stringify(company_zefix_data) }} -->
      </div>
  </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {},
  data() {
    return {
      disabledInputs: false,
      scoring_attributes: [],
      showLoader: true,
      type: '1',
    };
  },
  watch: {},
  created() {
    this.getScoringAttributes(this.type);
  },
  methods: {
    async getScoringAttributes(type) {
      this.showLoader = true
      await this.$http.post(`settings/getScoringAttributes/${type}`).then((res) => {
        if (res.data.status == 200) {
          this.scoring_attributes = res.data.value;
        }
        this.showLoader = false
      }).catch(error => {
        this.showLoader = false
      })
    },
    async onSubmit(id){
      this.showLoader = true
     let scoring_attribute =  this.scoring_attributes.find(scoring => scoring.id === id)
      await this.$http.post("settings/updateScoring", { "scoring_attribute" : scoring_attribute }).then((res) => {
        if (res) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Updated successfully",
              icon: "SuccessIcon",
              variant: "success",
            },
          })
        }
      })
      this.getScoringAttributes();
      // console.log('id: ',id);
      // console.log('this.scoring_attributes: ',  this.scoring_attributes.find(bird => bird.id === id));
    },
  }
}
</script>
<style>
.position-arrow{
  align-items: center;
}
.nav-tabs{
  width: 18% !important;
}

</style>
